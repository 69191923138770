<template>
  <product-list />
</template>

<script>

import ProductList from '@/components/action-product/ProductList.vue'

export default {
  components: {
    ProductList,
  },
}
</script>
